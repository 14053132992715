import React, {useEffect, useState} from 'react'
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import PrimaryMenu from "../components/PrimaryMenu";
import PageHeadingBox from "../components/PageHeadingBox";
import FeatureContent from "../components/FeatureContent";
import SectionNav from "../components/SectionNav";
import Tuition from "../components/Tuition";
import ColumnSection from "../components/ColumnSection";

const Parttime = ({pageContext: pagePassedContext}) => {
    
    console.log(pageContext)

    const [pageContext, setContext] = useState(pagePassedContext);

    let data = pageContext.content.json_data.data;
    let nav = pageContext.flyoutnav;
    let startdateblocks = data.startdateblocks;
    let instructor = data.instructor;
    let coursedetails = data.coursedetails;
    let tuition = data.tuition

    useEffect(function(){
        fetch("http://api.vanarts.com:3000/pages/"+pageContext.content.id).then(response=>{
            return response.json();
        }).then(function(json){
            setContext(json)
        })
    }, [])
    

    return (
        <Layout nav={nav}>
            <Hero image={data.cover}>
                <PrimaryMenu nav={nav}/>
            </Hero>
            
            <PageHeadingBox boxlabel={data.sectionTitle} title={data.name}>
                <div dangerouslySetInnerHTML={{__html: data.intro}}></div>
            </PageHeadingBox>

            <SectionNav linkset="part-time"/>

            <div className="section-handle row" id="intro">
                <article className="standard-content wide-8_12" id="overview">
                    <div className="pad">
                        <div dangerouslySetInnerHTML={{__html: data.overview}}/>
                    </div>
                </article> 
                <div className="introPhoto wide-4_12">
                        <h3>Upcoming Course Dates</h3>
                        <div className="nextStartDates">
                            {
                                startdateblocks.map(entry=>(
                                    <div className="date">
                                        <span className="smallday">{entry.date}</span>
                                    </div>
                                ))
                            }
                        </div>
                </div>  
            </div>

            <div id="outline"></div>
            {
                coursedetails.title && 
                <ColumnSection data={coursedetails}></ColumnSection>
            }

            {
                instructor.highlightText &&
            
                <FeatureContent caption={instructor.highlightText} cover={instructor.cover} theme="small">
                    <div dangerouslySetInnerHTML={{__html: instructor.content}} id="instructor"/>
                </FeatureContent>
            }

            <article className="standard-content extra-pad" id="tuition">
                <div className="tabintro">
                    <h1 className="sectionHeader">{tuition.sectionTitle}</h1>
                </div>
               <Tuition data={tuition}/>
            </article>
        </Layout>
    )
}

export default Parttime;