import React from "react";
import "../SubPageNav/SubPageNav.css";
import "./PageHeadingBox.css";

export default function PageHeadingBox({title,children, boxlabel}){

    if (boxlabel)
    {  
        return (
            <div className="navbox">
                <div className="content">
                    <h1>{title}</h1>
                    {children}
                </div>
            
                <span className="boxlabel">{boxlabel}</span>
            </div>
        )
    } else {
        return (
            <div className="navbox nopad">
                <div className="content">
                    <h1>{title}</h1>
                    {children}
                </div>
            </div>
        )
    }
}