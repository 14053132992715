import React from "react"
import "./SectionNav.css"

export default function SectionNav({linkset}){
    if (linkset == "part-time"){
        return (
            <div className="sectionNav">
                <a href="#intro">Overview and Start Dates</a>
                <a href="#outline">Outline</a>
                <a href="#instructor">Meet the Instructor</a>
                <a href="#tuition">Tuition</a>
                <a href="#tuition">Register</a>
            </div>
        )
    } else {
        return (
            <div className="sectionNav">
                <a href="#">Overview</a>
                <a href="#">Curriculum</a>
                <a href="#">Work</a>
                <a href="#">Testimonials</a>
                <a href="#">Faculty</a>
                <a href="#">Tuition</a>
            </div>
        )
    }
}
