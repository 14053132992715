import React from "react"
import "./ColumnsSection.css";

export default function ColumnsSection({data}){
    let colclass = "wide-12_12";
    if (data.col2)
    {
        colclass = "wide-6_12";
    }

    return (
        <div className="columnribbon">
        <article className="column_content standard-content">
            <header>
                <h3>{data.title}</h3>
                <div dangerouslySetInnerHTML={{__html: data.intro}}></div>
            </header>
            <div className="standard-content row">
                {
                    data.col1 && 
                    <div className={"col "+colclass}>
                        <div className="handle">
                            <div dangerouslySetInnerHTML={{__html: data.col1}}></div>
                        </div>
                    </div>
                }
                
                {
                    data.col2 && 
                    <div className={"col "+colclass}>
                        <div className="handle">
                            <div dangerouslySetInnerHTML={{__html: data.col2}}></div>
                        </div>
                    </div>
                }
            </div>
        </article>
        </div>
    )
}