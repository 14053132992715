import React from "react";

import "./Hero.css";

export default function Hero({image, children}){

    return (
        <div className="heroNoSlide" style={{backgroundImage: `url("http://api.vanarts.com:3000/assets/${image}")`}}>
            {children}
        </div>
    )
}