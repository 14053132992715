import React from "react";
import "./FeatureContent.css"

export default function FeatureContent({caption, children, cover, theme}){
    
    return (
        <section className={"row featured-content "+theme}>
            <figure>
                <picture>
                    <img src={"http://api.vanarts.com:3000/assets/"+cover} alt={caption}/>
                </picture>
                <figcaption>{caption}</figcaption>
            </figure>
            <article>
                {children}
                {/* <a className="cta-arrowLink" href="/about-us/">
                    <span>
                        <span className="text">Learn More About VanArts</span>
                    </span>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.51" height="12.193" viewBox="0 0 12.51 12.193">
                            <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M5.319,3.464l.62-.62a.667.667,0,0,1,.947,0l5.428,5.425a.667.667,0,0,1,0,.947L6.885,14.644a.667.667,0,0,1-.947,0l-.62-.62a.671.671,0,0,1,.011-.958L8.695,9.861H.67A.669.669,0,0,1,0,9.191V8.3a.669.669,0,0,1,.67-.67H8.695L5.33,4.422A.666.666,0,0,1,5.319,3.464Z" transform="translate(0 -2.647)" fill="#fff"></path>
                        </svg>
                    </span>
                </a> */}

            </article>
        </section>
    )
}