import React from "react"
import "./Tuition.css";

export default function Tuition({data}){
    return (
        <div className="tuition">
            <div className="cost">
                <div className="domestic">
                    <h2>Canadian</h2>
                    <span className="price">{data.canadian}</span>
                </div>
                <div className="domestic">
                    <h2>International</h2>
                    <span className="price">{data.international}</span>
                </div>
            </div>
            <div className="additional">

                 <div dangerouslySetInnerHTML={{__html: data.additionalinfo}} id="instructor"></div>
                <a href="#" className="cta-primary">Click to Apply Now</a>
            </div>
        </div>
    )
}